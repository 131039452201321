<template>
  <div class="float_window_wrap"
       ref="wrap">
    <div class="close_btn">
      <button @click="close"><img src="@/assets/error/Close.png"
             alt=""></button>
    </div>
    <div class="top_move"
         ref="top"></div>
    <div class="star">
      <img v-for="index of windowData.star"
           :key="index"
           src="@/assets/error/star_a.png"
           alt="">
      <div v-if="windowData.star<5"
           style="display:inline-block">
        <img v-for="index of 5-windowData.star"
             :key="index"
             src="@/assets/error/star.png"
             alt="">
      </div>
    </div>
    <div class="title">{{windowData.title}}</div>
    <div class="time">{{windowData.time}}</div>
    <div style="display: flex;
align-items: flex-start;
gap: 12px;
align-self: stretch;">
      <div class="rateAndCount">
        <div class="rate">
          <div v-if="Number(windowData.score)>=0"><span class="num">{{windowData.score}}</span><span style="font-size:16rem;line-height:24rem;padding-left:4rem">%</span></div>
          <div class='texts'
               style="width: 58%;"
               v-else>{{windowData.score}}</div>
          <div class='texts'
               v-show="Number(windowData.score)>=0">目前得分率</div>
        </div>
        <span style="width: 1rem;
height:24rem;
flex-shrink: 0;background: var(--light-cccccc, #CCC);"></span>

        <div class="rate">
          <div><span class="num">{{windowData.hope_score}}</span><span v-show="Number(windowData.hope_score)>=0"
                  style="font-size:16rem;line-height:24rem;padding-left:4rem">%</span></div>
          <div class='texts'
               v-show="Number(windowData.hope_score)>=0">目标得分率</div>
        </div>
      </div>
      <div class="count">
        <div><span class="num">{{windowData.count}}</span></div>
        <div class='texts'>练习次数</div>
      </div>
    </div>
    <div class="text3"
         v-if="windowData.title">
      功夫不负有心人，恭喜达标！<img src="@/assets/error_book/mapIcon.png"
           alt="">
    </div>
    <div class="button_wrap">
      <button v-if="windowData.type=='lecture'">
        <img src="@/assets/error/讲义.png"
             alt=""
             title="讲义">
        <span>讲义</span>
      </button>
      <button @click="showVideo"
              v-show="windowData.video_list&&windowData.video_list.length != 0">
        <div class="img"> <img src="@/assets/error/视频.png"
               alt=""
               title="视频"> </div>
        <span>视频</span>

      </button>
      <button v-show="windowData.type=='knowledge'"
              @click="doPaper">
        <div class="img"><img src="@/assets/error/练习.png"
               alt=""
               title="练习"></div>

        <span>练习</span>
      </button>
    </div>
    <VideoDialog ref="VideoFormRef" />
    <!-- <VideoForm ref="VideoFormRef" /> -->
  </div>
</template>

<script >

import VideoDialog from './videoCopy.vue'

export default {
  components: {
    VideoDialog
  },
  data () {
    return {
      windowData: {},
      videoRef: {
        list: [],
        dialogVisible: false
      },
      oX: 0,
      oY: 0,
      istouch: false,
      hope_score: null
    }
  },
  mounted () {
    this.$refs.top.addEventListener("mousedown", this.handler)
    this.$refs.top.addEventListener("touchstart", this.handlerTouch, false)
  },
  methods: {
    handler (e) {
      this.oX = e.pageX - this.$refs.wrap.offsetLeft
      this.oY = e.pageY - this.$refs.wrap.offsetTop
      // this.$refs.top.addEventListener("mousemove", this.move)
      document.addEventListener("mousemove", this.move,)
      document.addEventListener("mouseup", this.stop)
      this.istouch = false
    },
    move (e) {
      if (this.istouch) {
        // e.preventDefault()
        e.stopPropagation();
        document.addEventListener("touchend", this.stop)
        this.$refs.wrap.style.left = e.touches[0].pageX - this.oX + 'px'
        this.$refs.wrap.style.top = e.touches[0].pageY - this.oY + 'px'
      } else {
        document.addEventListener("mouseup", this.stop)
        this.$refs.wrap.style.left = e.pageX - this.oX + 'px'
        this.$refs.wrap.style.top = e.pageY - this.oY + 'px'
      }
      // console.log("移动", e)
    },
    stop (e) {
      // this.$refs.top.removeEventListener("mousemove", this.move)
      if (this.istouch) {
        document.removeEventListener("touchmove", this.move, false)
      } else {
        document.removeEventListener("mousemove", this.move)
      }

      // document.removeEventListener("mouseup", this.stop)
    },

    handlerTouch (e) {
      // e.preventDefault()
      e.stopPropagation();
      this.oX = e.touches[0].pageX - this.$refs.wrap.offsetLeft
      this.oY = e.touches[0].pageY - this.$refs.wrap.offsetTop
      // this.$refs.top.addEventListener("mousemove", this.move)
      document.addEventListener("touchmove", this.move, false)
      document.addEventListener("touchend", this.stop)
      this.istouch = true
    },
    close () {
      this.$parent.reset()
    },
    // 练习
    doPaper () {
      //  3考点做卷(6) 5知识点训练(4) 
      // console.log("data", this.windowData)
      let params
      if (this.windowData.type == 'checkPoint') {
        params = {
          check_point_id: this.windowData.check_point_id,
          check_point_2_id: this.windowData.check_point_2_id,
          selecting_checkpoint: 0,
          type: 6
        }
        this.$router.push('/doPaper?type=3&form=' + JSON.stringify(params))
      }
      if (this.windowData.type == 'knowledge') {
        params = {
          knowledge_id: this.windowData.knowledge_id,
          selecting_checkpoint: 0,
          is_weak: 0,
          subject_id: this.windowData.subjectId,
          type: 4,
        }
        this.$router.push('/doPaper?type=5&form=' + JSON.stringify(params))
      }
    },
    showVideo () {
      if (this.windowData.video_list.length > 0) {
        this.$refs.VideoFormRef.tableData = this.windowData.video_list
        this.$refs.VideoFormRef.dialogVisible = true
      }
      else this.$message.warning("暂无视频")
    },
  }
}
</script>

<style lang="scss" scoped>
.float_window_wrap {
  width: 320rem;
  z-index: 999;
  // height: 290rem;
  position: absolute;
  top: 40rem;
  right: 40rem;
  background: #ffffff;
  padding: 24rem;
  box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 16rem;
  // font-family: PingFang SC-Regular, PingFang SC;
  .close_btn {
    position: absolute;
    right: 24rem;
    button {
      background: #fff;
      border: 0;
      cursor: pointer;
      width: 24rem;
      height: 24rem;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .top_move {
    background-color: transparent;
    width: 80%;
    height: 40rem;
    position: absolute;
    top: 0;
    cursor: move;
  }
  .star {
    line-height: 0;
    img {
      margin-right: 4rem;
      width: 16rem;
      height: 16rem;
    }
  }
  .title {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 20rem;
    font-weight: 500;
    color: #333333;
    line-height: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .time {
    font-size: 16rem;
    font-weight: 400;
    color: #999999;
    line-height: 24rem;
  }
  .text3 {
    color: var(--light-666666, var(--neutral-color-666666, #666));
    margin-bottom: 16rem;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem; /* 150% */
  }
  .count {
    display: flex;
    padding: var(--spacer-24_m, 24rem);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // flex: 1 0 0;
    width: 69rem;
    align-self: stretch;
    border-radius: var(--radius-16_s, 16rem);
    background: var(--light-fafafa, #fafafa);
    margin: 16rem 0;
    .num {
      font-size: 20rem;
      font-weight: 500;
      line-height: 30rem;
    }
    .texts {
      font-weight: 400;
      line-height: 20rem;
      color: #999;
      font-size: 12rem;
    }
  }
  .rateAndCount {
    display: flex;
    width: 191px;
    padding: var(--spacer-24_m, 24px) 0px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-16_s, 16px);
    background: var(--light-f-5-f-5-f-5, #f5f5f5);
    margin: 16rem 0;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 0 0;
    }
    .rate {
      font-size: 16rem;
      .num {
        font-size: 20rem;
        font-weight: 500;
        line-height: 30rem;
      }
      .texts {
        font-weight: 400;
        line-height: 20rem;
        color: #999;
        font-size: 12rem;
      }
    }
  }
  .button_wrap {
    display: flex;
    justify-content: space-around;

    button {
      background: transparent;
      border: 0;
      padding: 6rem 12rem;
      flex: 1;
      cursor: default;
      display: flex;
      border-radius: 8rem;
      opacity: 1;
      border: 1rem solid #2196f3;
      font-size: 0;
      align-items: center;
      justify-content: center;
   
      margin-right: 12rem;
      &:last-of-type {
        margin-right: 0;
      }
      .img {
        width: 24rem;
        height: 24rem;
      }
      img {
        width: 100%;
        height: 100%;
      }
      span {
        display: inline-block;
        font-size: 16rem;
        // font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #2196f3;
        padding-left: 2rem;
        height: 24rem;
        line-height: 24rem;
      }
    }
  }
}
</style>