import axios from 'axios'
// import basicUrl from './basicUrl'
import router from '@/router'
import { Message, Loading } from 'element-ui'
import getMd5Form from '@/utils/getMd5Form.js'

var basicUrl = process.env.NODE_ENV === 'development' ? "http://10.10.11.250:705" : 'https://slbk.sunray365.com'
console.log('process.env.NODE_ENV', process.env.NODE_ENV)

export const instances = axios.create({
  baseURL: basicUrl,
  timeout: 3600000,
});

export const http_file = axios.create({
  baseURL: basicUrl,
  timeout: 3600000,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  transformRequest: [(data) => {
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }
    return formData
  }]
})

// 设置放行路由，放行错误控制
var role = [
  '/api/v1/student/export_stat',
  '/api/v1/combination/stats_export',
  '/api/v1/my/print_pdf'
]

function getTime () {
  if (window.localStorage.getItem('userInfo')) {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    var time = Date.parse(new Date()).toString(); //精确到毫秒
    time = time.substr(0, 10); //精确到秒
    if (userInfo) {
      if (userInfo.exp - time <= 180) {
        window.sessionStorage.removeItem('userInfo')
        setTimeout(() => {
          Message.error('登录已过期，请重新登录')
          return true
        }, 100);
        window.localStorage.clear()
        router.push('/login?not_auto_login=1')
      }
    }
  }
}


export var requestSign = {
  number: 0,
  setNumber: function (number) {
    requestSign.number = number
  }
}

export var loadings
function openLoading () {
  // 开启全局loading 
  if (!requestSign.number) {
    loadings = Loading.service({
      fullscreen: true,
      target: document.querySelector('#app'),
      text: '正在加载中!',
      spinner: 'el-icon-loading'
    });
  }
  requestSign.number += 1
}

function closeLoading () {
  // return
  setTimeout(() => {
    requestSign.number -= 1
    if (requestSign.number < 0) {
      requestSign.number = 0
    }
    if (requestSign.number == 0) {
      loadings.close()
    }
  }, 500);
}
function addInterceptors (face) {
  face.interceptors.request.use(config => {
    if (config.method == 'get') {
      if (!config.params) {
        config.params = {}
      }
      config.params.sign = getMd5Form(config.params)
    } else {
      if (!config.data) {
        config.data = {}
      }
      config.data.sign = getMd5Form(config.data)
    }

    let urlToken = router.history.current.query.token
    let userInfo = null
    if (urlToken) {
      userInfo = {
        token: urlToken
      }
    }
    if (!userInfo) {
      userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    }
    if (userInfo) {
      config.headers['Authorization'] = `Bearer ${userInfo.token || ''}`
    }
    openLoading()
    return config
  })
  face.interceptors.response.use(async config => {
    closeLoading()

    if (role.findIndex(item => item == config.config.url) != -1) {
      return config
    }

    if (config.data.status === undefined && config.data.msg === undefined) {
      return config.data
    }

    config = config.data
    return await new Promise((resolve, reject) => {
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.msg == 'error') {
          Message.error(config.data.msg)
        } else {
          Message.error(config.msg)
        }
        if (config.status == 410000) {
          window.localStorage.clear()
          router.push('/login?not_auto_login=1')
        }
        reject(config)
      }
    })
  })
}

addInterceptors(instances)
addInterceptors(http_file)
