<template>
  <div class="content">
    <div class="bgtop">
      <div class="topLeft">
        <img class="goUp"
             @click="backAnswer"
             src="@/assets/interesing/退出@2x.png"
             alt="">
        <!-- <span> {{subject_name}}考点记忆</span> -->
      </div>
      <div style="display: flex;gap: 32rem;align-items: center;">
        <div class="goal_text">为理想而战<img src="@/assets/medal.png"
               style="height: 36rem"
               alt="">
        </div>
        <div class="text"
             @click="toGoal">{{subject_name}}目标分{{ paper_duration }}分 ></div>
      </div>
      <div class="subject">
        <el-dropdown style="margin-left:20rem;margin-top:-5rem;color:#fff;font-size:26rem">
          <span class="el-dropdown-link">
            {{subject_name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in subjects"
                              @click.native.prevent="checkItem(item)"
                              :key="item.subject_id + ''">{{item.subject_name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="topic">
      <div class="analysis_warp">
        <!-- <div class="top">
      <TopCard ref="TopCardRef"
               text="答案解析"
               :showBack="true" />
    </div> -->
        <div class="content">
          <!-- 题目详情 -->
          <div class="left"
               ref="errorLeftRef">

            <!-- 右侧知识点导图 -->
            <div class="right">
              <div class="map">
                <LineMap ref="mapRef" />
                <!-- @setFloatData="setFloatData" -->
              </div>
            </div>
            <!-- 浮动窗口，知识点相关内容 -->
            <div class="float_window"
                 v-if="floatData&&showFlag">

              <FloatWindow :windowData="floatData"
                           ref="windowRef"
                           v-if="floatData.title" />

            </div>
          </div>
        </div>
      </div>
    </div>

    <ScoreDialog ref="ScoreDialogref" />
  </div>
</template>
<script>
import { getPhysicalMemory } from '@/api/Aaswer.js'
import TopCard from '@/views/components/top_card/index.vue'
import FloatWindow from '@/views/physicalMemory/components/floatWindow.vue'
import LineMap from '@/views/physicalMemory/components/lineMap.vue'
import ScoreDialog from '@/views/physicalMemory/components/scoreDialog.vue'
import { favoriteInfo, errorInfo, getPoint, getKnowledge, getLecture, editComment, editCollectComment } from '@/api/errorbasis.js'


export default {
  data () {
    return {
      topicListData: {},
      list: [],
      index: 0,
      info: {},
      question: {},
      subjects: [],
      questionKnowledge: [],//知识点数据
      questionCheckPoint: [],//考点数据
      textarea: '',
      floatData: {},
      compactData: {},
      comment: '',
      isFocus: false,
      thisTopicId: '',
      sessionNodeList: [],
      isMove: false,
      clickX: 0,
      clickLeftWidth: 0,
      userId: 0,
      showFlag: false,
      subject_name: '',
      totalScore: 0,
      paper_duration: 0,
    }
  },
  components: {
    TopCard, FloatWindow, LineMap, ScoreDialog
  },
  async mounted () {
    this.totalScore = JSON.parse(window.localStorage.getItem('totalScore'));
    this.$EventBus.on('totalScoreEdit', data => {
      this.totalScore = data
    })
    // this.getList()
    this.getSubjectList()
    this.sessionNodeList = JSON.parse(window.sessionStorage.getItem("sessionNodeList")) || []

    document.addEventListener('mousemove', this.moveDiv)
    document.addEventListener('mouseup', this.moveDisAble)
  },
  beforeRouteLeave (to, from, next) {
    if (to.path != '/doPaper') {
      window.sessionStorage.removeItem('tipic_id')
      window.sessionStorage.removeItem('sessionNodeList')
    }
    next()

  },
  async created () {
    let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let userId = Number(userinfo.user_id)
    this.userId = userId
  },
  methods: {
    reset () {
      this.$refs.mapRef.cancelSelected()
      this.showFlag = false
    },
    getSubjectList () {
      this.$http({
        url: '/api/v1/cp/subject_list',
        method: 'get'
      }).then(res => {
        let totalScore = 0; // 定义变量用于累加分数
        let subjectListData = [];
        subjectListData = res.data.list.filter(item => item.subject_id !== 13).map(item => {
          totalScore += item.score;

          if ((item.subject_id === 7 || item.subject_id === 12 || item.subject_id === 11) && item.score == 0) {
            item.score = 130; // 设置为130
          } else if (item.score == 0) {
            item.score = 90; // 其他设为90
          }

          return {
            subject_id: item.subject_id,
            score: item.score,
          }
        });
        window.localStorage.setItem('subjectListData', JSON.stringify(subjectListData))
        window.localStorage.setItem('totalScore', totalScore)
        this.subjects = res.data.list
        if (window.localStorage.getItem('physical_subjectId')) {
          this.checkItem(JSON.parse(window.localStorage.getItem('physical_subjectId')))
        } else {
          this.checkItem(this.subjects[0])
        }

      })
    },
    toGoal () {
      // this.$router.push('/studyGoal')
      this.$refs.ScoreDialogref.dialogVisible = true
    },
    checkItem (item) {
      window.localStorage.setItem('hope_score', JSON.stringify(item.hope_score_rate))
      window.localStorage.setItem('physical_subjectId', JSON.stringify(item))
      this.subject_name = item.subject_name
      this.paper_duration = item.score

      this.getList(item)
    },
    backAnswer () {
      this.$router.push({
        path: '/home',
      })
    },
    moveDisAble (bool) {
      this.isMove = false
      document.onselectstart = function () {
        return true
      }
    },
    moveDiv (e) {
      if (this.isMove) {
        let divWidth = this.$refs.errorLeftRef.clientWidth
        let screenWidth = document.body.clientWidth
        let moveX = e.clientX - this.clickX
        if ((screenWidth - divWidth <= 375 && screenWidth - e.clientX <= 375) || (divWidth <= 514 && e.clientX <= 514)) {
          return
        }
        // this.$refs.errorLeftRef.style.width = e.clientX - 0.0521 * screenWidth + 'px'
        this.$refs.errorLeftRef.style.width = this.clickLeftWidth + moveX + 3 + 'px'
      }
    },
    //处理讲义数据
    flatlectureList (arr) {
      let list = []
      arr.forEach(item => {
        if (!item.lecture_id) {
          return
        }
        let obj = {
          id: item.lecture_id,
          type: 'lecture',
          topic: item.lecture_name,
          parentId: 0,
          children: []
        }
        list.push(obj)
      });
      return list;
    },

    //处理考点数据
    flatCheckPointList (arr) {
      let list = []
      arr.forEach(item => {

        let obj = {
          type: 'checkpoint',
          children: []
        }
        if (item.check_point_2_id) {
          if (!item.check_point_2_id) {
            return
          }
          obj.id = item.check_point_2_id
          obj.parentId = item.check_point_id
          obj.topic = item.check_point_2_desc + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>'
        } else {
          if (!item.check_point_id) {
            return
          }
          obj.id = item.check_point_id
          obj.parentId = 0
          obj.topic = item.check_point_desc + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>'
        }

        if (item.chilren && item.chilren.length) {
          obj.children = this.flatCheckPointList(item.chilren)
        }

        list.push(obj);
      });
      return list;
    },

    //处理知识点数据
    flatKnowledgeList (arr) {
      let list = []
      arr.forEach((item, index) => {
        // let obj = {
        //   id: item.knowledge_id,
        //   type: 'knowledge',
        //   topic: item.knowledge_desc + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>',
        //   parentId: item.knowledge_parent_id,
        //   children: [],
        //   score: item.score
        // }
        let obj = {
          direction: index % 2 == 0 ? 'left' : 'right',
          id: item.knowledge_id,
          topic: item.knowledge_desc + '<span style="font-family: iconfont;font-size:26rem;vertical-align: -14%;line-height: 22rem;margin-right: -6px ">&#xe604;</span>',
          score: item.score,
          type: 'knowledge',
          children: []
        }
        if (item.child && item.child.length) {
          obj.children = this.flatKnowledgeList(item.child)
        }
        list.push(obj)
      });
      return list;
    },


    async getList (item) {
      console.log('subject_id', item);
      const { data } = await getPhysicalMemory({
        uid: this.userId,
        subject_id: item.subject_id ? item.subject_id : ''
      })
      this.question = data;
      let list = {
        id: 'topic_root',
        topic: `${item.subject_name}考点`,
        children: this.flatKnowledgeList(this.question)
      }
      // this.question.forEach((item, index) => {
      //   console.log('item0,item',item);

      //   // if (item.child && item.child.length > 0) {
      //   //   list.children.push({
      //   //     direction: index % 2 == 0 ? 'left' : 'right',
      //   //     id: item.knowledge_id,
      //   //     topic: item.knowledge_desc,
      //   //     score: item.score,
      //   //     type: 'knowledge',
      //   //     parentId:"topic_root",
      //   //     children: this.flatKnowledgeList(item.child)
      //   //   })
      //   // }
      // })

      this.$refs.mapRef.init(list)
    },
    // 设置浮窗数据
    async setFloatData (id, parentId, type) {
      this.showFlag = true
      console.log('id, parentId, type', id, parentId, type)
      let node = this.isSessionNode(type, id) //查找节点有无缓存
      if (node.id) {
        this.floatData = node.data
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = node.data
        })
        return
      }
      let form
      if (type == 'checkpoint') {
        const { data } = await getPoint({
          check_point_id: id
        })
        form = {
          type: 'checkPoint',
          // check_point_id: parentId ? parentId : id,
          check_point_2_id: parentId ? data.check_point_id : '',//二级考点
          check_point_id: parentId ? '' : data.check_point_id,//一级级考点
          title: data.check_point_desc,//标题
          star: data.check_point_star ? data.check_point_star : 0,//考点星级 （二级考点没有星级）
          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          video_list: data.video_list,//关联的考点视频
          hope_score: JSON.parse(window.localStorage.getItem('hope_score'))

        }
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('checkpoint', id, form)
      }
      else if (type == 'knowledge') {
        const { data } = await getKnowledge({
          knowledge_id: id
        })
        form = {
          type: 'knowledge',
          knowledge_id: data.knowledge_id,
          subjectId: data.subject_id,
          title: data.knowledge_desc,//标题
          star: data.knowledge_point_star ? data.knowledge_point_star : 0,//知识点星级
          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          hope_score: JSON.parse(window.localStorage.getItem('hope_score')),
          video_list: data.video_list//关联的考点视频
        }
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('knowledge', id, form)
      } else if (type == 'lecture') {
        const { data } = await getLecture({
          lecture_id: id
        })
        form = {
          type: 'lecture',
          title: data.lecture_name,//标题
          time: data.paper_time,//做卷时间
          score: data.score,//得分率
          count: data.count,
          hope_score: JSON.parse(window.localStorage.getItem('hope_score')),
          lecture_file: data.lecture_file//关联的考点视频
        }
        this.floatData = form
        this.$nextTick(() => {
          this.$refs.windowRef.windowData = form
        })
        this.setSessionNode('lecture', id, form)
      }

    },
    isSessionNode (type, id) {
      this.sessionNodeList = JSON.parse(window.sessionStorage.getItem("sessionNodeList")) || []

      if (!this.sessionNodeList) return false
      let node = this.sessionNodeList.find(item => item.type == type && item.id == id
      )

      if (!node) { return false }
      return node
    },
    setSessionNode (type, id, data) {
      this.sessionNodeList.push({
        type: type,
        id,
        data
      })
      window.sessionStorage.setItem("sessionNodeList", JSON.stringify(this.sessionNodeList))
    }

  }
}
</script>
<style lang="scss" scoped>
.content {
  cursor: pointer;
  .bgtop {
    width: 100%;
    height: 100rem;
    padding: 26rem 40rem;
    box-sizing: border-box;
    background: #2196f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .goal_text {
      color: var(--light-ffffff, #fff);
      font-size: 28rem;
      font-style: normal;
      font-weight: 700;
    }
    .text {
      overflow: hidden;
      color: var(--light-ffffff, #fff);
      text-overflow: ellipsis;
      font-size: 28rem;
      font-style: normal;
      font-weight: 400;
    }
    .topLeft {
      display: flex;
      justify-content: center;
      align-items: center;

      .goUp {
        width: 48rem;
        height: 48rem;
        margin-right: 24rem;
      }
      .data {
        width: 32rem;
        height: 32rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
      }
    }
    .topRight {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 48rem;
        height: 48rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
        margin-left: 24rem;
      }
    }
  }
  .topic {
    // padding: 40rem 40rem 32rem 40rem;
    box-sizing: border-box;
    .analysis_warp {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: #f6f6f6;
      font-family: PingFang SC-Regular, PingFang SC;
      .top {
        width: 100%;
        height: 100rem;
      }

      .content {
        display: flex;
        // height: calc(100vh - 100rem);
        height: 100vh;
        margin-bottom: 10rem;
        .left {
          // width: 680rem;
          width: 100%;
          height: 100%;
          // overflow-x: hidden;
          // overflow-y: auto;
          .box {
            overflow: hidden;
            border-radius: 5rem;
            margin: 28rem 10rem 19rem 28rem;
            margin-right: 10px;
            background: #fff;
            min-height: 393rem;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            .title {
              width: 100%;
              height: 54rem;
              line-height: 54rem;
              background: #2196f3;
              padding: 0 19rem;
              box-sizing: border-box;
              font-size: 26rem;
              justify-content: space-between;
              // font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              text-align: left;
              .next_btn {
                .txt {
                  display: inline-block;
                  margin-left: 30rem;
                }
              }
              .txt {
                img {
                  margin-top: 17rem;
                  width: 13rem;
                  height: 22rem;
                }
              }
            }
            .topic_ontent {
              padding: 9rem 19rem;
              font-size: 22rem;
              min-height: 280rem;
              color: #333;
              line-height: 40rem;
              overflow: auto;
              ::v-deep img {
                vertical-align: middle;
              }
              .topic {
                ::v-deep p {
                  width: 100%;
                  overflow: auto;
                }
              }
            }

            .answer,
            .answerImg,
            .comment {
              padding: 19rem;
              font-size: 24rem;
              color: #333;
              line-height: 40rem;
              .comment_title {
                font-weight: 600;
              }
              div {
                margin-bottom: 10rem;
              }
            }
            .answerImg {
              img {
                width: 100%;
              }
            }
          }
          .other {
            padding: 9rem 19rem;
            .subtitle {
              font-size: 24rem;
              // font-weight: 700;
              color: #2196f3;
              line-height: 50rem;
            }
            .list {
              font-size: 22rem;
              line-height: 40rem;
              font-weight: 400;
              color: rgba(102, 102, 102, 0.85);
            }
          }
        }
        .right {
          flex: 1;
          // width: 1080rem;
          margin: 29rem 24rem 29rem 16rem;
          height: calc(100vh - 46rem);
          background: #fff;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 0px 0px 0px 0px;
          .map {
            height: 100%;
          }
        }

        .float_window {
          cursor: default;
        }
        .comment_text {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .comment_icon {
          width: 28rem;
          height: 28rem;
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
      }
      .move_line {
        width: 4px;
        height: 100vh;
        background: #fff;
        box-shadow: 0px 0px 4px #bbc;
        border: 0;
        padding: 0;
        cursor: col-resize;
      }
      /* 整个滚动条 */
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      /* 滚动条上的按钮 (上下箭头). */
      ::-webkit-scrollbar-button {
        display: none;
      }
      /* 滚动条上的滚动滑块. */
      ::-webkit-scrollbar-thumb {
        background-color: rgba(202, 202, 210, 0.8);
        border-radius: 50px;
        cursor: pointer;
      }
      /* 滚动条没有滑块的轨道部分 */
      ::-webkit-scrollbar-track-piece {
        border-radius: 20px;
      }
      /* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      ::v-deep .el-input--medium {
        font-size: 24rem;
      }
    }
  }
}
::v-deep .txt {
  width: 63%;
  height: 57%;
  text-align: center;
  position: absolute;
  top: 28% !important;
  left: 17%;
  color: #fff;
}
::v-deep .wrap .bottom_tab .left .txt :nth-child(1) {
  line-height: normal !important;
  font-size: 20rem !important;
}
</style>